import { FC, PropsWithChildren } from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import appConfiguration from "src/appConfiguration";
import globalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import { UserNotificationProvider } from "src/hooks/useMessageHandler";
import { PromptProvider } from "src/hooks/usePrompt";
import { theme } from "src/theme";
import { prefixer } from "stylis";
import { LookupsProvider } from "./contexts/LookupsContext";
import { ProfileProvider } from "./contexts/ProfileContext";
import { ReceiptQueueProvider } from "./contexts/ReceiptQueueContext";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { t } from "i18next";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const emotionCache = createCache({
  key: "bv",
  stylisPlugins: [prefixer],
  prepend: true,
});
interface Props extends PropsWithChildren {
  [key: string]: any;
}

const queryClient = new QueryClient();

const inputGlobalStyles = <GlobalStyles styles={globalStyles as any} />;

const Root: FC<Props> = ({ children }: Props) => {
  const AuthProvider = appConfiguration.use().authContextProvider;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CacheProvider value={emotionCache}>
          <SnackbarProvider
            dense
            preventDuplicate
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Router basename={process.env.PUBLIC_URL}>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    localeText={{
                      nextMonth: t("common:nextMonth"),
                      previousMonth: t("common:previousMonth"),
                      fieldDayPlaceholder: () => t("common:dayPlaceholder"),
                      fieldMonthPlaceholder: () => t("common:monthPlaceholder"),
                      fieldYearPlaceholder: () => t("common:yearPlaceholder"),
                    }}
                  >
                    <ProfileProvider>
                      <LookupsProvider>
                        <ReceiptQueueProvider>
                          {inputGlobalStyles}
                          <ScrollReset />
                          <UserNotificationProvider>
                            <PromptProvider>{children}</PromptProvider>
                          </UserNotificationProvider>
                        </ReceiptQueueProvider>
                      </LookupsProvider>
                    </ProfileProvider>
                  </LocalizationProvider>
                </QueryClientProvider>
              </AuthProvider>
            </Router>
            <SnackbarUtilsConfigurator />
          </SnackbarProvider>
        </CacheProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Root;

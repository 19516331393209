import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "src/assets/icons/LogoutIcon";
import UserSettingIcon from "src/assets/icons/UserSettingIcon";
import ImageUpload from "src/components/ImageUpload";
import useAuth from "src/hooks/useAuth";
import { snackActions } from "src/utils/SnackbarUtils";
import { useProfile } from "../contexts/ProfileContext";
import ChangePasswordForm from "./Forms/ChangePasswordForm";
import UserSettingsModal from "src/components/Modals/UserSettingsModal";

const UserProfileCard: FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { state: profileState, dispatch, updateAvatar } = useProfile();
  const { user: userProfile, isChangingPassword } = profileState;
  const [isOpen, setIsOpen] = useState(false);
  const handleImageChange = async (event) => {
    try {
      updateAvatar(event);
      dispatch({ type: "SET_CLOSED" });
      snackActions.success("Successfully updated avatar");
    } catch (err) {
      snackActions.error("Failed to update avatar");
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      dispatch({ type: "SET_CLOSED" });
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };
  const handleProfileOpen = () => {
    setIsOpen(true);
    dispatch({ type: "SET_CLOSED" });
  };
  const { t } = useTranslation();

  return (
    <>
    <Dialog
      open={profileState.open}
      onClose={() => dispatch({ type: "SET_CLOSED" })}
      PaperProps={{
        sx: {
          borderRadius: "15px",
          position: "fixed",
          top: 64,
          right: 20,
          m: 0,
        },
      }}
      aria-labelledby="draggable-user-profile-card"
      hideBackdrop
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: "200px",
        }}
      >
        {!isChangingPassword ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box pb={2}>
              <ImageUpload
                assetPath={
                  profileState.avatar === null
                    ? profileState.avatar
                    : `data:image/jpeg;base64,${profileState.avatar}`
                }
                onImageChange={handleImageChange}
              />
            </Box>

            <Typography variant="body2" textAlign="center" gutterBottom>
              {userProfile?.FullName
                ? userProfile?.FullName
                : userProfile?.EmployeeName}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {userProfile?.CompanyName}
            </Typography>

            <Divider
              sx={{ border: "1px solid #F0F0F0", marginY: 1, width: "100%" }}
            />

            <MenuList sx={{ color: "#333", pb: 0 }}>
                <MenuItem onClick={handleProfileOpen}>
                <ListItemIcon>
                  <UserSettingIcon fontSize="small" sx={{ color: "#333" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">{t("common:usersetting")}</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" sx={{ color: "#333" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">{t("common:logout")}</Typography>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </Box>
        ) : (
          <ChangePasswordForm />
        )}
        {!isChangingPassword ? (
          <>
            <Divider
              sx={{
                border: "1px solid #F0F0F0",
                marginY: 2,
                width: "100%",
              }}
            />
            <Button onClick={() => dispatch({ type: "CHANGE_PASSWORD" })}>
              {t("common:changePassword")}
            </Button>
          </>
        ) : null}
      </DialogContent>
      </Dialog>
      {isOpen && (
        <UserSettingsModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default UserProfileCard;

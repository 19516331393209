const E = {
  title: {
    sampleReception: "Sample Reception",
  },
  actions: {
    newRecord: "New Record",
    viewScannedCoCs: "View Scanned COCs",
  },
  tiles: {
    workingNow: "Working Now",
    inQueue: "In Queue",
    receivedToday: "Received Today",
    receivedEarlier: "Received Earlier",
    priority: "Priority",
  },
  table: {
    column: {
      prefix: "Prefix",
      receiptNumber: "Receipt #",
      cocNumber: "COC #",
      clientName: "Client Name",
      sampleType: "Sample Type",
      note: "Note",
      receivedOn: "Received On",
      numberOfCoolers: "# Coolers",
      comments: "Comments",
      priorityScore: "Priority",
      status: "Status",
    },
    menu: {
      open: "Open",
      statusChange: "Status Change",
      delete: "Delete",
      edit: "Edit",
    },
    row: {
      hold: "H",
      sub: "S",
      preApp: "P",
      eCOC: "E",
      prelog: "PL",
      working: "Working",
      waiting: "Waiting",
      prepaid: "Prepaid",
      payPending: "Pay Pending",
      paid: "Paid",
      pending: "Pending",
      unknown: "N/A",
    },
  },
  modal: {
    changeStatus: {
      changeStatusHeading: "Change Status",
      changeStatusTo: "Change Status To:",
      waiting: "Waiting",
      working: "Working",
      cancel: "Cancel",
      updateStatus: "Update Status",
    },
    confirmDelete: {
      confirmDeletion: "Confirm Deletion",
      deleteMessage: "Do you really want to delete the following Receipt #? ",
    },
    inProgress: {
      inProgressHeading: "In Progress",
      inProgressMessage: "This Receipt # has already been started.",
    },
  },
  error: {
    noReceipts: "There are no Receipts.",
  },
};

const F = {
  title: {
    sampleReception: "Réception d'échantillons",
  },
  actions: {
    newRecord: "Nouveau Dossier",
    viewScannedCoCs: "AFFICHER LES CDRS",
  },
  tiles: {
    workingNow: "Actif",
    inQueue: "En Attente",
    receivedToday: "Reçu aujourd'hui",
    receivedEarlier: "Reçu précédemment",
    priority: "Priorité",
  },
  table: {
    column: {
      prefix: "Préfixe",
      receiptNumber: "No. de réception",
      cocNumber: "No. de CDR",
      clientName: "Nom du client",
      sampleType: "Type d'échantillon",
      note: "Note",
      receivedOn: "Reçu le",
      numberOfCoolers: "Nbr. de glacières",
      comments: "Commentaires",
      priorityScore: "Priorité",
      status: "Statut",
    },
    menu: {
      open: "Ouvrir",
      statusChange: "Changement de statut",
      delete: "Effacer",
      edit: "Modifier",
    },
    row: {
      hold: "H",
      sub: "S",
      preApp: "P",
      eCOC: "E",
      prelog: "PL",
      working: "En Cours",
      waiting: "Disponible",
      prepaid: "Prépayée",
      payPending: "Paiement en cours",
      paid: "Payé",
      pending: "En attente",
      unknown: "N/A",
    },
  },
  modal: {
    changeStatus: {
      changeStatusHeading: "Modifier le statut",
      changeStatusTo: "Changer le statut en:",
      waiting: "Disponible",
      cancel: "Annuler",
      updateStatus: "Changer le statut",
    },
    confirmDelete: {
      confirmDeletion: "Confirmer l'effacement",
      deleteMessage:
        "Voulez-vous vraiment supprimer le numéro de reçu suivant ?",
    },
    inProgress: {
      inProgressHeading: "En cours",
      inProgressMessage: "Ce No. de réception a déjà commencé.",
    },
  },
  error: {
    noReceipts: "Il n'y a pas de reçus.",
  },
};

const resources = { E, F };

export default resources;
